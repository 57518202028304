@import 'main/util.scss';


html {
  height: 100%;
}

body {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#70b7ff+0,95c1ed+100 */
  background: #70b7ff; /* Old browsers */
  background: -moz-linear-gradient(top,  #70b7ff 0%, #95c1ed 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#70b7ff), color-stop(100%,#95c1ed)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #70b7ff 0%,#95c1ed 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #70b7ff 0%,#95c1ed 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #70b7ff 0%,#95c1ed 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #70b7ff 0%,#95c1ed 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#70b7ff', endColorstr='#95c1ed',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
}

#login-padding {
  padding: 50px 20px;
}

.login-gradient {

}

.login-warning {
  padding: 20px;

  margin: auto;
  max-width: 490px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);

  border-radius: 5px;

  z-index: 500;

  margin-bottom: 15px;

  &.wide {
    max-width: 700px;
  }

}

.login-container {
  background-color: white;
  padding: 20px;

  margin: auto;
  max-width: 490px;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);

  border-radius: 5px;


  /* LOGO */
  .logo-container {
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    height: 88px;
    margin-bottom: 20px;

      img {
        max-height: 100%;
        max-width: 100%;
        vertical-align: middle;
      }

  }

  z-index: 500;

  margin-bottom: 15px;

  &.wide {
    max-width: 700px;
  }

  &.extra-wide {
    max-width: 1000px;
  }

  &.links {
    padding-bottom: 0;

    .col-md-6,.col-md-12 {
      padding-bottom: 20px;
    }
  }

  & .panel-collapsible {

    .panel-heading {
      h4:before {
        content: "\f055";
        font-family: "FontAwesome";
        margin-right: 0.5em;
      }
      &[aria-expanded=true] h4:before {
        content: "\f056";
      }
    }

    .panel-collapse div {
      padding: 1em 1em 0.5em 1em;
    }
  }

  &.very-pale-yellow-background {
    @extend .very-pale-yellow-background;
  }

  &.very-pale-red-background {
    @extend .very-pale-red-background;
  }

  &.very-pale-lime-green-background {
    @extend .very-pale-lime-green-background;
  }

  &.very-pale-orange-background {
    @extend .very-pale-orange-background;
  }

  &.slightly-desaturated-magenta {
    background-color: #cc99cc;
  }

  &.slightly-desaturated-cyan {
    background-color: #99cccc;
  }
}

.login-container--md-fixed-width {
  @extend .login-container;

  @media (min-width: 992px) {
    width: 460px;
  }
}

.login-logo-footer {
  margin-top: 20px;
  text-align: center;
}

.login-grass {
  width: 100%;
  height: 166px;
  position: fixed;
  z-index: -100;
  bottom: 0px;
  background: url( "../../images/themes/default/grass.png" ) repeat-x;
  left: 0;
}

.school-select-list {
  .school-info {
    h3 {
      margin: 0;
    }
  }

  .school-button {
    padding-top: 5px;
  }
}

.tinySchoolLogo {
  width: 40px;
}

#login-school-name {
  font-family: Georgia;
  font-style: italic;
  font-size: 22px;
}

#unsubscribe-login-link {
  white-space: normal;
}

#weAreHiring {
  background-color: white;
  padding: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.19);
  border-radius: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}

/* Set-Up account */
.setup-container {
  margin-top: 60px;
}

.setup-user-details {
  text-align: right;
  position: absolute;
  top: 15px;
  right: 20px;

  background: white;
  border-radius: 3px;
  padding: 5px 10px;
}

// SETUP FRAMEWORKS AND FLAG SETS
.setup-frameworks {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}

.setup-frameworks__tile {
  flex-basis: 100%;
  border-bottom: 1px solid #d6d6d6;
  padding: 15px;

  &:last-child {
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .setup-frameworks__tile {
    flex-basis: 50%;

    &:nth-child(odd) {
      border-right: 1px solid #d6d6d6;
    }

    &:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
    }
  }
}

.framework-select {
  font-size: 16px;

  label {
    font-weight: 400;
  }
}

/* Fade-In-Up animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0,20px,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0,20px,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@keyframes fadeInLeft {
  from {
    transform: translate3d(20px,0,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    transform: translate3d(20px,0,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.fadeInLeft {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInLeft;
}

.has-navbar {
  padding-top: 70px;
}

.login-encyclopedia-link {
  background-color: #993399;
  color: white;

  &:hover {
    color: white;
    background-color: #843355;
  }

  &:focus {
    background-color: #993399;
  }
}

.quote-author {
  color: #6F6F6F;
}
